import React from 'react'
import Link from 'gatsby-link'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

import CustomSVG from '../../components/custom-svg'

const styles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1.5, 1.75),
    paddingRight: theme.spacing(1.5),
    boxShadow: theme.shadows[3],
    '&:last-child': {
      marginBottom: 0
    },
    // Add margin when we are showing multiple panels
    '& + .MuiPaper-root': {
      marginTop: theme.spacing(2)
    }
  },

  linkIcon: {
    marginRight: theme.spacing(1),
    '& svg': {
      width: '35px',
      height: '35px',
      display: 'block'
    }
  },

  linkIconBg: {
    alignSelf: 'flex-start',
    justifyContent: 'center',
    display: 'flex',
    marginRight: theme.spacing(1),
    background: theme.palette.secondary.light,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.75),
    width: '35px',
    height: '35px',
    color: theme.palette.primary.main,

    '& svg': {
      maxWidth: '23px',
      maxHeight: '23px',

      '& path': {
        fill: theme.palette.primary.main
      }
    }
  },

  linkWrapper: {
    marginBottom: '-2px',
    overflow: 'hidden'
  },

  link: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '32px',
    color: 'inherit',
    textDecoration: 'none'
  },

  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },

  subtitle: {
    color: theme.palette.grey[500],
    display: 'block'
  },

  linkIconGo: {
    color: theme.palette.primary.main,
    marginLeft: 'auto',
    marginRight: 0,
    width: '24px',
    height: '24px',
    boxSizing: 'content-box',
    paddingLeft: theme.spacing(2)
  }
}))

export default function linkPanel(props) {
  const classes = styles()
  const icon = props.icon ? <CustomSVG content={props.icon.iconSource} /> : null

  return (
    <Paper className={classes.root}>
      <Link to={props.to} className={classes.link}>
        {icon ? (
          <div className={classes.linkIcon + ' ' + (props.iconBg ? classes.linkIconBg : null)}>
            {icon}
          </div>
        ) : null}

        <div className={classes.linkWrapper}>
          <Typography variant="h4" className={classes.title}>
            {props.title}
          </Typography>

          {props.subtitle ? (
            <Typography variant="caption" className={classes.subtitle}>
              {props.subtitle}
            </Typography>
          ) : null}
        </div>

        <NavigateNextIcon className={classes.linkIconGo} />
      </Link>
    </Paper>
  )
}
